// ###################################
// HEADING LINKS CSS
// ###################################



.heading-link {
	margin: -6px 0 0 4px;
	display: inline-block;
	vertical-align: middle;
	position: relative;

	i {
		font-size: 16px;
	}

	.heading-link__popup {
		background-color: $blue-link;
		color: white;
		border-radius: 999999px;
		padding: 7px 15px 8px 15px;
		white-space: nowrap;
		opacity: 0;
		visibility: hidden;
		position: absolute;
		bottom: calc(100% + 1px);
		left: 50%;
		z-index: 5;
		font-weight: 700;
		font-size: 12px;
		line-height: 15px;
		pointer-events: none;
		@include transform(translateX(-50%));
		@include transition((opacity .25s ease-in-out, visibility .25s ease-in-out));

		&:after {
			width: 15px;
			height: 15px;
			background-color: $blue-link;
			display: block;
			position: absolute;
			bottom: -2px;
			left: 50%;
			z-index: -1;
			@include transform(translateX(-50%) rotate(45deg));
			content: '';
		}
	}

	&:hover {
		.heading-link__popup {
			opacity: 1;
			visibility: visible;
		}
	}
}







.editor-container {
	margin-bottom: 45px;
	padding: {
		left: 0;
		right: 0;
	}
}

.ql-container {
	font-family: "din-2014", Helvetica, Arial, sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 18px;
}

.ql-toolbar {
	img, svg {
		vertical-align: initial;
	}
}

.ql-editor {
	p,
	ol, ul,
	pre,
	blockquote,
	h1, h2, h3, h4, h5, h6 {
		margin-bottom: 16px;
	}

	ol, ul {
		padding-left: 0;
	}

	ul li:before {
		background-color: initial;
		position: relative;
		top: 5px;
		line-height: 0.1;
		font-size: 36px;
	}
}

//#region List Indentation Fixes

	$LIST_STYLE_WIDTH: 1.2em;
	$LIST_STYLE_MARGIN: 0.3em;
	$LIST_STYLE_OUTER_WIDTH: $LIST_STYLE_MARGIN + $LIST_STYLE_WIDTH;
	$MAX_INDENT: 9;

	@for $num from 1 through $MAX_INDENT {

		.ql-indent-#{$num}:not(.ql-direction-rtl) {
			padding-left: (0.5em*$num);
		}
		li.ql-indent-#{$num}:not(.ql-direction-rtl) {
			padding-left: (0.5*$num + $LIST_STYLE_OUTER_WIDTH);
		}
		.ql-indent-#{$num}.ql-direction-rtl.ql-align-right {
			padding-right: (0.5em*$num);
		}
		li.ql-indent-#{$num}.ql-direction-rtl.ql-align-right {
			padding-right: (0.5*$num + $LIST_STYLE_OUTER_WIDTH);
		}
	}

	li.ql-direction-rtl {
		padding-right: $LIST_STYLE_OUTER_WIDTH;
		> .ql-ui:before {
			margin-left: $LIST_STYLE_MARGIN;
			margin-right: -1*$LIST_STYLE_OUTER_WIDTH;
			text-align: left;
		}
	}
//#endregion

//#region Header Fixes
%h1-styling,
%h2-styling,
%h3-styling,
%h4-styling,
%h5-styling {
	color: $blue;
   margin: 0 0 30px 0;
   font-weight: 700;
}

%h1-styling {
	color: $red;
	margin: 0 0 14px 0;
   font-size: 54px;
   line-height: 57px;
}

%h2-styling {
	margin: 0 0 15px 0;
   font-size: 36px;
   line-height: 39px;
}

%h3-styling {
	margin: 0 0 15px 0;
   font-size: 33.75px;
   line-height: 36.75px;
}

%h4-styling {
	margin: 38px 0 11px 0;
	// margin: 38px 0 6px 0;
   font-size: 24px;
   line-height: 27px;
}

%h5-styling {
	margin: 0 0 17px 0;
	font-weight: 400;
   font-size: 20px;
   line-height: 23px;
}

.ql-container .ql-editor {
	h1 {
		@extend %h1-styling;
 }
 
 h2 {
		@extend %h2-styling;
 }
 
 h3 {
		@extend %h3-styling;
 }
 
 h4 {
		@extend %h4-styling;
 }
 
 h5 {
		@extend %h5-styling;
 }
}
//#endregion

//#region Text Alignment and Text Styles
.ql-align-center {
	text-align: center;
}
.ql-align-right {
	text-align: right;
}
.ql-align-justify {
	text-align: justify;
}

.ql-font-serif {
	font-family: serif;
}
.ql-font-monospace {
	font-family: monospace;
}
//#endregion

//#region Code
pre.ql-syntax,
.ql-snow .ql-editor pre.ql-syntax {
	background-color: $blue;
	color: #f8f8f2;
	overflow: visible;
	white-space: pre-wrap;
	margin-bottom: 5px;
	margin-top: 5px;
	padding: 5px 10px;
}
//#endregion

//#region Blockquote
blockquote.blockquote {
	$margin-y: 5px;
	border-left: 4px solid #ccc;
	margin-bottom: $margin-y+16px;
	margin-top: $margin-y;
	padding-left: 16px;
}
//#endregion
// ###################################
// TYPOGRAPHY CSS
// ###################################



%h1-styling,
%h2-styling,
%h3-styling,
%h4-styling,
%h5-styling {
	color: $blue;
   margin: 0 0 30px 0;
   font-weight: 700;
}

%h1-styling {
	color: $red;
	margin: 0 0 14px 0;
   font-size: 54px;
   line-height: 57px;
}

%h2-styling {
	margin: 0 0 15px 0;
   font-size: 36px;
   line-height: 39px;
}

%h3-styling {
	margin: 0 0 15px 0;
   font-size: 33.75px;
   line-height: 36.75px;
}

%h4-styling {
	margin: 38px 0 11px 0;
	// margin: 38px 0 6px 0;
   font-size: 24px;
   line-height: 27px;
}

%h5-styling {
	margin: 0 0 17px 0;
	font-weight: 400;
   font-size: 20px;
   line-height: 23px;
}

h1 {
   @extend %h1-styling;
}

h2 {
   @extend %h2-styling;
}

h3 {
   @extend %h3-styling;
}

h4 {
   @extend %h4-styling;
}

h5 {
   @extend %h5-styling;
}

h1,
h2,
h3,
h4,
h5,
p,
span {
   &.h1-styling {
      @extend %h1-styling;
   }

   &.h2-styling {
      @extend %h2-styling;
   }

   &.h3-styling {
      @extend %h3-styling;
   }

   &.h4-styling {
      @extend %h4-styling;
   }

   &.h5-styling {
      @extend %h5-styling;
   }
}

p {
	margin: 0 0 16px 0;

	&.p-large {
		font-size: 24px;
		line-height: 29px;
	}

	&.p-small {
		font-size: 16px;
		line-height: 19px;
	}

	&.no-documents {
		font-style: italic;
	}
}

a {
	color: $blue-link;
	text-decoration: underline;

	&:hover {
		color: $blue-link;
	}
}

ul {
	margin: 0 0 16px 0;
	padding: 0;
	list-style-type: none;

	li {
		margin: 0 0 10px 0;
		padding: 0 0 0 15px;
		position: relative;

		&:before {
			width: 6px;
			height: 6px;
			background-color: $grey-text;
			border-radius: 999999px;
			display: block;
			position: absolute;
			top: 10px;
			left: 0;
			content: '';
			pointer-events: none;
		}
	}

	&.unlisted {
	   margin: 0;
	   padding: 0;

	   li {
	      margin: 0;
	      padding: 0;
	      position: static;

	      &:before {
	      	display: none;
	      }
	   }
	}

	&.inline {
	   li {
	      display: inline-block;
	   }
	}
}

#CybotCookiebotDialog,
#CookiebotWidget {
	ul {
		li {
			&:before {
				content: none;
			}
		}
	}
}






// ###################################
// BREADCRUMBS CSS
// ###################################



.breadcrumbs {
	width: 100%;
	height: 30px;
	margin: 20px 0 8px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;

	ul.unlisted.inline.breadcrumbs__nav {
		li {
			margin-right: 24px;
			position: relative;
			font-size: 16px;
			line-height: 19px;

			&:after {
				color: $grey-text;
				display: block;
				position: absolute;
				top: 1px;
				right: -18px;
				font-family: 'Font Awesome 6 Free';
            font-weight: 700;
            font-size: 12px;
            content: '\f054';
				pointer-events: none;
			}

			a {
				text-decoration: underline !important;
			}

			&:last-child {
				margin: 0;

				&:after {
					display: none;
				}
			}
		}
	}

	ul.unlisted.inline.breadcrumbs__links {
		li {
			color: $blue;
			margin-right: 16px;
			font-weight: 700;
			font-size: 16px;
		
			img {
				margin-top: -2px;
			}

			&:last-child {
				margin-right: 0;
			}
		}

		&.subtle {
			li {
				color: #ddd;
				&:hover {
					color: $blue;
				}
			}
		}
	}
}






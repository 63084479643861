// ###################################
// MODAL CSS
// ###################################



table span.modal-trigger {
	color: $blue-link;
	text-decoration: underline;
	cursor: pointer;
}

.modal {
	width: 100%;
	height: 100%;
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999999;

	.modal__shade {
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}

	.modal__content {
		width: 50%;
		max-height: calc(100vh - 60px);
		background-color: white;
		padding: 42px 39px 15px 39px;
		overflow-y: auto;
		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		@include transform(translateX(-50%) translateY(-50%));

		.modal__content__close {
			color: $grey;
			position: absolute;
			top: 18px;
			right: 20px;
			z-index: 5;
			font-size: 20px;
			cursor: pointer;
		}

		.h4-styling {
			margin-top: 0;
			margin-bottom: 7px;
		}

		.modal__content__event-location {
			margin-bottom: 35px;
			font-size: 16px;
			line-height: 19px;

			li {
				margin-bottom: 9px;
				padding-left: 23px;
				position: relative;

				i {
					color: $grey-light;
					position: absolute;
					top: 1px;
					left: 0;
				}

				span {
					font-weight: 700;
				}
			}
		}

		table {
			width: 100%;
		}
	}

	&.modal--open {
		display: block;
	}
}








.loadingOverlayContainer {
	position: relative;
}

.loadingOverlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;

	display: flex;
	align-items: center;
	justify-content: center;
}
// ###################################
// BOOTSTRAP CSS
// ###################################



.container-fluid {
   padding-right: 30px;
   padding-left: 30px;
}

.row {
   margin-right: -15px;
   margin-left: -15px;

   & > * {
      padding-right: 15px;
      padding-left: 15px;
   }
}






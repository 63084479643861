// ###################################
// CALENDAR CSS
// ###################################



ul.unlisted.calendar {
	margin-bottom: 22px;

	li {
		margin-bottom: 10px;

		span {
			margin: 0 17px 0 0;
			display: inline-block;
			font-size: 16px;
		}

		a {
			color: $blue-link;
			text-decoration: underline;

			&:hover {
				color: $blue-link;
				text-decoration: underline;
			}
		}
	}
}


// ###################################
// PAGINATION CSS
// ###################################



ul.unlisted.inline.pagination {
	margin: 0 0 38px 0;

	li {
		margin-right: 14px;

		a {
			color: $grey-text !important;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		&:last-child {
			margin-right: 0;
		}

		&.pagination__prev,
		&.pagination__next, {
			i {
				color: $blue-link;
				margin-top: -3px;
				display: inline-block;
				vertical-align: middle;
				font-size: 14px;
			}
		}

		&.pagination__prev {
			margin-right: 22px;
		}

		&.pagination__next {
			margin-left: 8px;
		}

		&.pagination__current {
			color: $blue-link;	
		}
	}

	&.pagination--table {
		margin-top: -32px;
	}
}






// ###################################
// SIDEBAR CSS
// ###################################



.sidebar {
	width: calc(100% + 30px);
	height: 100%;
	min-height: 100vh;
	margin-left: -30px;
	padding: 20px 20px 20px 20px;
	background-color: $grey-background;

	h2 {
		margin: 0 0 6px 0;
		font-size: 18px;
		line-height: 27px;

		a {
			color: $blue !important;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	ul.unlisted {
		padding-top: 9px;

		li {
			margin-bottom: 9px;
			padding-left: 18px;
			position: relative;

			.sidebar__sub-trigger {
				width: 15px;
				height: 19px;
				background-color: $grey-background;
				border: none;
				margin: 0;
				padding: 0;
				position: absolute;
				top: 1px;
				left: 0px;
				z-index: 5;
				line-height: 0;
			
				i {
					color: $grey;
					position: absolute;
					top: 9px;
					left: 0;
					font-size: 16px;
					line-height: 0;
				}
			}

			a {
				color: black;
				display: block;
				line-height: 21px;
				text-decoration: none;

				&.active {
					font-weight: bold;
				}

				&:hover {
					text-decoration: underline;
				}
			}

			ul.unlisted.sidebar__sub {
				display: none;
			}

			&.sidebar__with-sub {
				&.sidebar__with-sub--open {
					& > .sidebar__sub-trigger {
						height: 17px;

						i {
							top: 8px;
							left: 1px;
							@include transform(rotate(90deg));
						}
					}

					& > .sidebar__sub {
						display: block;
					}
				}
			}
		}
	}

	&.sidebar--with-lines {
		ul.unlisted {
			position: relative;

			&:before {
				width: 1px;
				height: calc(100% - 28px);
				border-right: 1px dashed $grey-text-light;
				display: block;
				position: absolute;
				top: 15px;
				left: 4px;
				z-index: 1;
				content: '';
			}
		}
	}

	&.sidebar--without-lines {
		ul.unlisted {
			li {
				padding-left: 0;

				&.sidebar__current-page {
					font-weight: 700;
				}
			}
		}
	}
}






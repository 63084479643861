// ###################################
// CORE CSS
// ###################################



html {
   -webkit-text-size-adjust: 100%;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

body {
   color: $grey-text;
   margin: 0;
   font-family: "din-2014",Helvetica,Arial,sans-serif;
   font-weight: 400;
   font-style: normal;
   font-size: 18px;
   line-height: 27px;
}

.hidden-for-accessibility {
   width: 1px;
   height: 1px;
   border: 0;
   overflow: hidden;
   margin: -1px;
   padding: 0;
   position: absolute;
   clip: rect(0 0 0 0);
}

main {
   // min-width: 992px;
}

.content-area {
   padding-bottom: 40px;

   h1 {
      &+.row.row--tables {
         margin-top: 31px;

         h2.h4-styling {
            margin-top: 0;
         }
      }
   }
}






// ###################################
// BUTTONS CSS
// ###################################



a, input, button {
	&.btn:not(.btn-link) {
		background-color: white;
		color: $blue;
		border: 3px solid $blue;
		border-radius: 999999px;
		@include box-shadow((0px 2px 2px 0px rgba(0,0,0,0.20)));
		padding: 15px 38px 13px 38px;
		margin-bottom: 15px;
		font-weight: 700;
		font-size: 18px;
		line-height: 21px;
		text-transform: uppercase;

		&:hover {
			background-color: $blue;
			color: white;
		}
	}
}






// ###################################
// TABLE CSS
// ###################################



.row.row--tables {
	margin-top: -38px;
	margin-bottom: -38px;
}

.table-responsive {
	margin: 0 0 46px 0;
}

table.table {
	// width: 100%;
	// margin: 0 0 46px 0;
	margin: 0;

	thead,
	tbody {
		tr {
			th,
			td {
				border-bottom: 1px solid $grey-light;
				padding: 11px 30px 11px 0;
				// overflow: hidden;
				// text-overflow: ellipsis;
				white-space: nowrap;
			
				// &:last-child {
				// 	width: 100%;
				// 	max-width: 0;
				// 	padding-right: 0;
				// }
			}
		}
	}

	thead {
		tr {
			th {
				padding-top: 0;
				font-weight: 700;
				font-size: 14px;
				line-height: 17px;
			}
		}
	}

	tbody {
		tr {
			td {
				font-size: 16px;
				line-height: 19px;

				img {
					margin-top: -3px;
				}
			}

			&.table__accordion-content__sub-trigger {
				td {
					padding: 0 !important;

					button {
						width: 100%;
						background-color: transparent;
						border: none;
						margin: 0;
						padding: 10px 0 10px 30px;
						display: block;
						// font-weight: 700;
						text-align: left;

						i {
							color: $grey;
							margin: -4px 12px 0 2px;
							display: inline-block;
							vertical-align: middle;
						}
					}
				}

				&.table__accordion-content__sub-trigger--open {
					td {
						button {
							i {
								@include transform(rotate(90deg));
							}
						}
					}
				}
			}

			&.table__accordion-content__sub-content {
				display: none;

				// td:first-child {
				// 	padding-left: 30px;
				// }

				&.table__accordion-content__sub-content--open {
					display: table-row;
				}
			}
		}

		&.table__accordion-trigger {
			tr {
				td {
					padding: 0 !important;

					button {
						width: 100%;
						background-color: transparent;
						border: none;
						margin: 0;
						padding: 10px 0 10px 0;
						display: block;
						font-weight: 700;
						text-align: left;

						i {
							color: $grey;
							margin: -4px 12px 0 2px;
							display: inline-block;
							vertical-align: middle;
						}
					}
				}
			}

			&.table__accordion-trigger--open {
				tr {
					td {
						button {
							i {
								@include transform(rotate(90deg));
							}
						}
					}
				}
			}
		}

		&.table__accordion-content {
			// visibility: collapse;
			display: none;
		
			&.table__accordion-content--open {
				// visibility: visible;
				display: table-row-group;
			}
		}
	}

	&.table--folders {
		thead,
		tbody {
			tr {
				th,
				td {
					&:first-child {
						width: 57px;
					}

					&:last-child {
						width: auto;
						max-width: none;
						padding-right: 30px;
					}
				}
			}
		}
	}

	&.table--simple {
		width: 100%;
		border-top: 1px solid $grey-light;

		tbody {
			tr {
				td {
					border-bottom: 1px solid $grey-light;
					padding: 11px 20px 11px 0;
					white-space: normal !important;
					vertical-align: top;
					font-size: 16px;
					line-height: 19px;

					&:first-child {
						font-weight: 700;
						font-size: 14px;
					}

					&:last-child {
						padding-right: 0;
					}
				}
			}
		}
	}
}






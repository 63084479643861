// ###################################
// CONTACT CSS
// ###################################

.contact {
	width: fit-content;
	border: 1px solid $grey-light;
	margin: 0 0 21px 0;
	padding: 4px 19px 7px 19px;

	& > .contact-info,
	& > .contact-info > * {
		display: inline-block;
	}

	& > .contact-details {
		width: fit-content;
		margin-bottom: 0;
	}

	h2,
	ul.unlisted.inline li {
		margin: 0 16px 0 0;
		font-size: 16px;
		line-height: 19px;
	}

	ul.unlisted.inline {
		li {
			a {
				i {
					color: $blue;
					margin: -2px 9px 0 0;
					display: inline-block;
					vertical-align: middle;
				}
			}

			&:last-child {
				margin: 0;
			}
		}
	}

	& + h2.h4-styling {
		margin-top: 0;
	}
}

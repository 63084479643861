// ###################################
// SEARCH RESULTS CSS
// ###################################



.search-results {
	h1.h3-styling {
		color: $red;
		margin-bottom: 51px;
	}

	.search-results__list {
		li {
			margin: 0 0 44px 0;

			a {
				color: $blue !important;
				margin: 0 0 6px 0;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				display: block;
				font-weight: 700;
				font-size: 20px;
				line-height: 23px;
				text-decoration: none;

				img {
					width: 16px;
					margin: -4px 10px 0 0;
					display: inline-block;
					vertical-align: middle;
				}
			}

			p {
				max-width: 100%;
				margin: 0;
				display: -webkit-box;
			   -webkit-line-clamp: 2;
			   -webkit-box-orient: vertical;
			   overflow: hidden;
			}
		}
	}
}






// ###################################
// HEADER CSS
// ###################################



header {
	width: 100%;
	//min-width: 992px;
	background-color: $blue;
	position: relative;

	.header__main {
		padding-top: 29px;
		padding-bottom: 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.header__main__logo {
			width: 265px;
			display: none;

			img {
				width: 100%;
			}
		}

		.header__main__logo-small {
			width: 91px;

			img {
				width: 100%;
			}
		}

		.header__main__search {
			font-size: 0;

			label {
				color: white;
			}

			.form-group,
			.header__main__search__submit {
				display: inline-block;
				vertical-align: middle;
				font-size: 18px;
			}

			.form-group {
				input.form-control {
					width: 280px;
					height: 40px;
					background-color: white;
					color: $grey-text;
					border: none;
					border-radius: 0;
					padding: 0 14px;
					font-size: 18px;
					line-height: 18px;
				
					&::-webkit-input-placeholder {
				      color: $grey-text-light;
				      font-size: 18px;
				      line-height: 18px;
				   }
				   &::-moz-placeholder {
				      color: $grey-text-light;
				      font-size: 18px;
				      line-height: 18px;
				   }
				   &:-ms-input-placeholder {
				      color: $grey-text-light;
				      font-size: 18px;
				      line-height: 18px;
				   }
				   &:-moz-placeholder {
				      color: $grey-text-light;
				      font-size: 18px;
				      line-height: 18px;
				   }
				}
			}

			.header__main__search__submit {
				width: 40px;
				height: 40px;
				background-color: $red;
				color: white;
				border: none;
				padding-top: 3px;

				i {
					font-size: 20px;
					line-height: 0;
				}
			}
		}

		nav.header__main__nav {
			ul.unlisted.inline {
				li {
					margin-left: 25px;

					a {
						color: white;
						font-weight: 700;
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}

					&.header__main__nav__login {
						padding-left: 31px;
						position: relative;

						&:before {
							width: 2px;
							height: 28px;
							background-color: white;
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							content: '';
						}

						a {
							i {
								margin: -0 9px 0 0;
								display: inline-block;
								vertical-align: middle;
								font-size: 20px;
							}
						}
					}

					&.header__main__nav__user {
						border-left: 2px solid white;
						padding-left: 31px;

						.header__main__nav__user__trigger {
							width: 32px;
							height: 32px;
							background-color: $blue-link;
							color: white;
							border: 1px solid white;
							border-radius: 999999px;
							margin-top: -1px;
							display: flex;
							justify-content: center;
							align-items: center;
							font-weight: 700;
							font-size: 14px;
							line-height: 14px;
							text-transform: uppercase;
							cursor: pointer;
						}

						ul.unlisted.header__main__nav__user__list {
							min-width: 251px;
							background-color: $blue-link;
							display: none;
							position: absolute;
							top: 100%;
							right: 0;
							z-index: 9999;

							li {
								width: 100%;
								color: white;
								border-bottom: 1px solid $blue;
								margin: 0;
								padding: 12px 20px 17px 20px;
								white-space: nowrap;
								font-weight: 400;
								font-size: 16px;
								line-height: 19px;

								span {
									display: block;
									font-weight: 700;
								}

								a {
									white-space: nowrap;
									display: block;
								}

								&:last-child {
									border: none;
								}
							}
						}

						&.header__main__nav__user--open {
							ul.unlisted.header__main__nav__user__list {
								display: block;
							}
						}
					}

					&:first-child {
						margin-left: 0;
					}
				}
			}
		}
	}

	.header__bottom {
		width: 100%;
		background-color: $blue-link;
		padding-top: 12px;
		padding-bottom: 12px;

		.col-12 {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		ul.unlisted.inline {
			li {
				color: white;
				font-size: 16px;
				line-height: 19px;

				a {
					color: white !important;
				}

				&:last-child {
					margin-right: 0 !important;
				}
			}

			&.header__bottom__breadcrumbs {
				li {
					margin-right: 25px;
					position: relative;

					&:after {
						color: white;
						display: block;
						position: absolute;
						top: 0;
						right: -18px;
						font-family: 'Font Awesome 6 Free';
	               font-weight: 700;
	               font-size: 12px;
	               content: '\f054';
						pointer-events: none;
					}

					&:last-child {
						&:after {
							display: none;
						}
					}
				}
			}

			&.header__bottom__contact {
				li {
					margin-right: 6px;

					i {
						margin: -4px 11px 0 0;
						display: inline-block;
						vertical-align: middle;
						font-size: 18px;
					}
				}
			}
		}
	}
}

@media (min-width: 992px) {
	header {
		.header__main {
			.header__main__logo {
				display: inline-block;
			}

			.header__main__logo-small {
				display: none;
			}

			.header__main__search {
				.form-group {
					input.form-control {
						width: 200px;
					}
				}
			}

			// nav.header__main__nav {
			// 	ul.unlisted.inline {
			// 		li {
			// 			&.header__main__nav__user {
			// 				ul.unlisted.header__main__nav__user__list {
			// 					top: calc(100% + 34px);
			// 				}
			// 			}
			// 		}
			// 	}
			// }
		}
	}
}

@media (min-width: 1100px) {
	header {
		.header__main {
			.header__main__logo {
				width: 313px;
			}

			.header__main__search {
				.form-group {
					input.form-control {
						width: 28vw;
					}
				}
			}

			// nav.header__main__nav {
			// 	ul.unlisted.inline {
			// 		li {
			// 			&.header__main__nav__user {
			// 				ul.unlisted.header__main__nav__user__list {
			// 					top: calc(100% + 38px);
			// 				}
			// 			}
			// 		}
			// 	}
			// }
		}
	}
}






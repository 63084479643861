// ###################################
// VIEW MORE CSS
// ###################################



.view-more {
	margin-bottom: 22px;

	.view-more__hidden {
		display: none;
	}

	button.view-more__trigger {
		background-color: transparent;
		color: $blue-link;
		border: none;
		padding: 0;

		.view-more__trigger__less {
			display: none;
		}

		i {
			margin: -2px 0 0 5px;
			display: inline-block;
			vertical-align: middle;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	&.view-more--visible {
		.view-more__hidden {
			display: block;
		}

		button.view-more__trigger {
			.view-more__trigger__less {
				display: inline-block;
			}

			.view-more__trigger__more {
				display: none;
			}

			i {
				margin-top: 1px;
				@include transform(rotate(180deg));
			}
		}
	}
}






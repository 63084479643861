// ###################################
// FOOTER CSS
// ###################################



footer {
	width: 100%;
	//min-width: 992px;
	background-color: $blue;
	padding-top: 33px;
	padding-bottom: 32px;

	ul.unlisted.inline {
		li {
			color: white;
			margin-right: 27px;
			position: relative;

			&:after {
				width: 1px;
				height: 17px;
				background-color: white;
				display: block;
				position: absolute;
				top: 5px;
				right: -16px;
				content: '';
			}

			span {
				margin: -4px 0 0 0;
				display: inline-block;
				vertical-align: middle;
				font-size: 14px;
			}

			a {
				color: white !important;
			}

			&:last-child {
				margin-right: 0;

				&:after {
					display: none;
				}
			}
		}
	}
}






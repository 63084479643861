@import "../styles/colors";

.container {
	display: flex;
	flex-direction: row;
}

.sidebar {
	max-width: 300px;
	display: flex;
	flex-direction: row-reverse;
	background-color: #E9EBEA;
}

.collapseBtn {
	max-height: 40px;
	margin-top: 1rem;
	border: none;
	background-color: transparent;
	color: $blue;
	padding: 0 1rem;
}

.sidebarContent {
	padding-left: 2rem;
	overflow: hidden;
}

.collapsed {
	.sidebarContent {
		max-width: 0;
		padding: 0;
	}
}

.content {
	flex-grow: 1;
	width: 100%;
	overflow: hidden;
}